import { Alert, Box, Stack, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  selectNotifications,
  shiftNotification,
} from "../../store/Notification/notificateStore";
import { randomKeyGeneration } from "../../utils/randomKeyGeneration";
import React from "react";

const NotificationContainer = styled(Box)({
  position: "fixed",
  top: "88px",
  right: "20px",
  width: "320px",
});

const DELATA_TIME = 2000;

export const Notification = () => {
  const { notifications } = useAppSelector(selectNotifications);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (notifications.length > 0) {
      const timer = setTimeout(() => {
        dispatch(shiftNotification());
      }, DELATA_TIME);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, notifications]);

  return (
    <NotificationContainer>
      <Stack sx={{ width: "100%" }} spacing={1}>
        {notifications.length > 0 &&
          notifications.map((notif) => {
            return (
              <Alert
                key={randomKeyGeneration()}
                sx={{ alignItems: "center" }}
                severity={notif.status}
              >
                {notif.text}
              </Alert>
            );
          })}
      </Stack>
    </NotificationContainer>
  );
};
